import { request, noTimeOutReq } from '@/utils/request.js'

//获取磁盘信息
export function getServerDiskApi () {
  return request({
    url: `/server/web/disk/status/get`,
    method: 'post',
  })
}

//获取安装包列表
export function getServerPackageListApi () {
  return request({
    url: `/server/package/list`,
    method: 'post',
  })
}

//根据类型获取安装包列表类型
export function getServerPackageTypeApi (type) {
  return request({
    url: `/server/package/list/by/${type}`,
    method: 'post',
  })
}


//上传安装包
export function uploadServerPackageApi (data, type) {
  return noTimeOutReq({
    url: `/server/package/upload`,
    method: 'post',
    data
  })
}

