<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-card class="box-card" shadow="hover" v-loading="loading">
          <div class="pane">
            <p class="pane_label">服务器硬盘使用量</p>
            <div
              v-if="!loading"
              ref="chart"
              id="chart"
              style="width: 100%; height: 260px"
            ></div>
            <span id="del_sapn_inner">(删除考试即可清理磁盘)</span>
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="box-card" shadow="hover">
          <div class="pane">
            <p class="pane_label">平台在线升级</p>
            <div class="pane_content">
              <el-form :inline="true">
                <el-form-item label="当前版本：">
                  <el-input
                    v-model="platformVersion"
                    placeholder
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="version_inner">
              <el-upload
                ref="uploadPlatformRef"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :limit="1"
                :show-file-list="false"
                :file-list="uploadFileList"
                :http-request="uploadServerPackage"
              >
                <div>
                  <el-button
                    v-throttle
                    type="primary"
                    icon="el-icon-upload"
                    size="medium"
                    @click="uploadPlatformVersion"
                    >上传新版本</el-button
                  >
                </div>
              </el-upload>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="box-card" shadow="hover">
          <div class="pane">
            <p class="pane_label">监考服务器管理</p>
            <div class="pane_content">
              <el-form :inline="true">
                <el-form-item label="当前版本：">
                  <el-input
                    v-model="invigilatorVersion"
                    placeholder
                    :disabled="true"
                  ></el-input>
                </el-form-item>
                <!-- <span class="el-link--inner">(当设备连接后会自动更新)</span> -->
              </el-form>
            </div>
            <div class="version_inner">
              <el-upload
                ref="uploadInvigilatorRef"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :limit="1"
                :show-file-list="false"
                :file-list="uploadFileList"
                :http-request="uploadServerPackage"
              >
                <div>
                  <el-button
                    v-throttle
                    type="primary"
                    icon="el-icon-upload"
                    size="medium"
                    @click="uploadInvigilatorVersion"
                    >上传新版本</el-button
                  >
                </div>
              </el-upload>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="box-card" shadow="hover">
          <div class="pane">
            <p class="pane_label">阅卷客户端管理</p>
            <div class="pane_content">
              <el-form :inline="true">
                <el-form-item label="当前版本：">
                  <el-input
                    v-model="markTerminalVersion"
                    placeholder
                    :disabled="true"
                  ></el-input>
                </el-form-item>
                <!-- <span class="el-link--inner">(当设备连接后会自动更新)</span> -->
              </el-form>
            </div>
            <div class="version_inner">
              <el-upload
                ref="uploadMarkTerminalRef"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :limit="1"
                :show-file-list="false"
                :file-list="uploadFileList"
                :http-request="uploadServerPackage"
              >
                <div>
                  <el-button
                    v-throttle
                    type="primary"
                    icon="el-icon-upload"
                    size="medium"
                    @click="uploadMarkTerminalVersion"
                    >上传新版本</el-button
                  >
                </div>
              </el-upload>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6" style="margin-top: 20px">
        <el-card class="box-card" shadow="hover">
          <div class="pane">
            <p class="pane_label">Windows考生端管理</p>
            <div class="pane_content">
              <el-form :inline="true">
                <el-form-item label="当前版本：">
                  <el-input
                    v-model="input"
                    placeholder
                    :disabled="true"
                  ></el-input>
                </el-form-item>
                <!-- <span class="el-link--inner">(当设备连接后会自动更新)</span> -->
              </el-form>
            </div>
            <div class="version_inner">
              <el-button
                v-throttle
                v-show="0"
                type="primary"
                icon="el-icon-upload"
                size="medium"
                >上传新版本</el-button
              >
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col :span="6" style="margin-top: 20px">
        <el-card class="box-card" shadow="hover">
          <div class="pane">
            <p class="pane_label">AI算力盒管理</p>
            <div class="pane_content">
              <el-form :inline="true">
                <el-form-item label="当前版本：">
                  <el-input
                    v-model="input"
                    placeholder
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="version_inner">
              <el-button
                v-throttle
                v-show="0"
                type="primary"
                icon="el-icon-upload"
                size="medium"
                >上传新版本</el-button
              >
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col :span="6" style="margin-top: 20px">
        <el-card class="box-card" shadow="hover">
          <div class="pane">
            <p class="pane_label">Android考生端管理</p>
            <div class="pane_content">
              <el-form :inline="true">
                <el-form-item label="当前版本：">
                  <el-input
                    v-model="examineeAndroidVersion"
                    :disabled="true"
                    placeholder
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="version_inner">
              <el-upload
                ref="uploadExamineeAndroidRef"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :limit="1"
                :show-file-list="false"
                :file-list="uploadFileList"
                :http-request="uploadServerPackage"
              >
                <div>
                  <el-button
                    v-throttle
                    type="primary"
                    icon="el-icon-upload"
                    size="medium"
                    @click="uploadExamineeVersion"
                    >上传新版本</el-button
                  >
                </div>
              </el-upload>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" style="margin-top: 20px">
        <el-card class="box-card" shadow="hover">
          <div class="pane">
            <p class="pane_label">学生终端FTP服务</p>
            <div class="pane_content">
              <el-form :inline="true">
                <el-form-item label="当前版本：">
                  <el-input
                    v-model="studentTerminalFTPVersion"
                    :disabled="true"
                    placeholder
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="version_inner">
              <el-upload
                ref="uploadStudentTerminalFTPRef"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :limit="1"
                :show-file-list="false"
                :file-list="uploadFileList"
                :http-request="uploadServerPackage"
              >
                <div>
                  <el-button
                    v-throttle
                    type="primary"
                    icon="el-icon-upload"
                    size="medium"
                    @click="uploadStudentTerminalFTPVersion"
                    >上传新版本</el-button
                  >
                </div>
              </el-upload>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import {
  getServerDiskApi,
  getServerPackageListApi,
  getServerPackageTypeApi,
  uploadServerPackageApi,
} from '@/api/system/deviceManage.js'
import { isEmpty } from '@/utils/util'
export default {
  name: 'deviceManage',
  data() {
    return {
      loading: false,
      charts: {
        chart: '',
      },
      input: '没有升级包，请上传',
      platformVersion: '没有升级包，请上传',
      invigilatorVersion: '没有升级包，请上传',
      markTerminalVersion: '没有升级包，请上传',
      examineeAndroidVersion: '没有升级包，请上传',
      studentTerminalFTPVersion: '没有升级包，请上传',
      serverPackageList: [],
      uploadType: 0,
      uploadFileList: [],
      uploadComponentRef: '',
      diskResult: {},
    }
  },
  mounted() {
    this.init()
    window.onresize = () => {
      this.reloadChart()
    }
  },
  watch: {},
  methods: {
    init() {
      this.getServerPackageList()
      this.getServerDisk()
    },
    initChart() {
      var chart = echarts.init(this.$refs.chart)
      this.charts.chart = chart
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: 'item',
          // formatter: "{a} <br/>{b}: {c} ({d}%)",
          formatter: '{b}<br/>{c}GB ({d}%)',
        },
        legend: {
          orient: 'vertical',
          top: '5%',
          left: '0',
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['45%', '65%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: Number(
                  this.diskResult.diskUsableSpace / 1024 / 1024 / 1024
                ).toFixed(2),
                name: '磁盘已使用',
              },
              {
                value: Number(
                  this.diskResult.diskFreeSpace / 1024 / 1024 / 1024
                ).toFixed(2),
                name: '磁盘未使用',
              },
            ],
          },
        ],
        color: ['red', 'black'],
      }
      // 使用刚指定的配置项和数据显示图表。
      chart.setOption(option)
    },
    //获取安装包列表
    getServerPackageList() {
      getServerPackageListApi().then((res) => {
        if (res.success) {
          this.serverPackageList = []
          this.serverPackageList = res.data
          if (!isEmpty(this.serverPackageList)) {
            this.serverPackageList.forEach((item) => {
              if (!isEmpty(item)) {
                let _version = ''
                _version = item.versionCode
                if (isEmpty(item.versionCode)) {
                  _version = '没有升级包，请上传'
                }
                if (!isEmpty(item.type)) {
                  item.type = Number(item.type)
                  switch (item.type) {
                    case 11:
                      this.platformVersion = _version
                      break
                    case 31:
                      this.invigilatorVersion = _version
                      break
                    case 41:
                      this.examineeAndroidVersion = _version
                      break
                    case 42:
                      this.studentTerminalFTPVersion = _version
                      break
                    case 51:
                      this.markTerminalVersion = _version
                      break
                  }
                }
              }
            })
          }
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg)
          }
        }
      })
    },
    //获取磁盘信息
    getServerDisk() {
      getServerDiskApi().then((res) => {
        if (res.success) {
          this.diskResult = {}
          this.diskResult = res.data
          console.log(this.diskResult)
          this.initChart()
        }
      })
    },
    uploadPlatformVersion() {
      this.uploadType = 11
      this.uploadComponentRef = 'uploadPlatformRef'
    },
    uploadInvigilatorVersion() {
      this.uploadType = 31
      this.uploadComponentRef = 'uploadInvigilatorRef'
    },
    uploadMarkTerminalVersion() {
      this.uploadType = 51
      this.uploadComponentRef = 'uploadMarkTerminalRef'
    },
    uploadExamineeVersion() {
      this.uploadType = 41
      this.uploadComponentRef = 'uploadExamineeAndroidRef'
    },
    uploadStudentTerminalFTPVersion() {
      this.uploadType = 42
      this.uploadComponentRef = 'uploadStudentTerminalFTPRef'
    },
    // upload组件钩子事件
    beforeAvatarUpload(file) {
      this.uploadFileList = []
      this.uploadFileList.push(file)
    },
    handleAvatarSuccess(response, file, fileList) {},
    uploadServerPackage() {
      let fd = new FormData()
      fd.append('file', this.uploadFileList[0])
      fd.append('type', this.uploadType)
      const loading = this.globalLoading('正在上传,请稍后...')
      uploadServerPackageApi(fd)
        .then((res) => {
          loading.close()
          if (res.success) {
            this.msgSuccess('上传成功')
            this.getServerPackageList()
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
          this.uploadType = 0
          this.clearFile()
        })
        .catch(() => {
          loading.close()
        })
    },
    clearFile() {
      let _ref = this.uploadComponentRef
      this.$refs[_ref].clearFiles()
      this.uploadFileList = []
    },
    reloadChart() {
      this.charts.chart.resize()
    },
  },
}
</script>
<style lang="scss" scoped>
.pane {
  height: 320px;
  padding: 10px;
}

.pane_label {
  font-size: 15px;
  font-weight: 500;
}

.pane_content {
  margin: 50px 0 0 30px;
}

::v-deep form {
  height: 85px;
}

.el-link--inner {
  color: #999;
  font-size: 15px;
  font-weight: 500;
  margin-left: 27%;
}

::v-deep .el-upload--text {
  // width: 100%;
}

.version_inner {
  text-align: center;
  margin-top: 50px;
}

#del_sapn_inner {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  color: #999;
  width: 100%;
}
</style>
